import styles from './PlaceholderCard.module.css'

const PlaceholderCard = () => {
  return (
    <div className={styles.placeholderCard} data-testid="placeholderCard">
      <div className={styles.placeholderImage}>
        <div className={styles.shimmer} />
      </div>
      <div className={styles.placeholderMetadataWrapper}>
        <div className={styles.placeholderMetadata}>
          <div className={styles.shimmer} />
        </div>
        <div className={styles.placeholderMetadata}>
          <div className={styles.shimmer} />
        </div>
        <div className={styles.placeholderMetadata}>
          <div className={styles.shimmer} />
        </div>
      </div>
    </div>
  )
}

export default PlaceholderCard
