import classnames from 'classnames'
import React from 'react'
import { differenceInDays } from 'date-fns'
import styles from './CardLabel.module.css'
import {
  dateStringToSwedishLocaleTimeZoneString,
  todayAsSwedishLocaleString,
} from 'frontend/utils/timeUtils'

export const handleAccessible = (daysLeft: number) => {
  if (daysLeft === null) return null

  if (daysLeft < 1) {
    return 'Går ut i dag'
  }
  if (daysLeft === 1) {
    return '1 dag kvar'
  }
  if (daysLeft < 8) {
    return `${daysLeft} dagar kvar`
  }
  return null
}

interface LabelProps {
  accessibleUntil?: string
  isCurrentProduct?: boolean
  nextEpisodeLabel?: string
}

const CardLabel = ({
  accessibleUntil,
  isCurrentProduct,
  nextEpisodeLabel,
}: LabelProps) => {
  let daysLeft
  if (accessibleUntil) {
    daysLeft = differenceInDays(
      new Date(dateStringToSwedishLocaleTimeZoneString(accessibleUntil, {})),
      new Date(todayAsSwedishLocaleString()),
    )
  }

  if ((!accessibleUntil || daysLeft < 0 || daysLeft >= 8) && !nextEpisodeLabel)
    return null

  return (
    <span
      className={classnames(styles.label, {
        [`${styles.active}`]: isCurrentProduct,
        [`${styles.nextEpisode}`]: nextEpisodeLabel,
      })}
    >
      {nextEpisodeLabel || handleAccessible(daysLeft)}
    </span>
  )
}

export default CardLabel
