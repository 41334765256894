import { EdithCardLayout } from 'backend/services/v1/edithService.types'
import classnames from 'classnames'
import Badges from 'components/Badges/Badges'
import { ContinuePlayingProgram } from 'components/ContinuePlaying/ContinuePlaying'
import NextImage from 'components/NextImage/NextImage'
import ProgressBar from 'components/ProgressBar/ProgressBarContainer'
import SpaLink from 'components/SpaLink/SpaLink'
import { MediaFormat, ProductType, ResponsiveImageType } from 'frontend/enums'
import { LinkProduct, Product, Program } from 'frontend/types'
import {
  handleAccessibleUntil,
  isExternalLinkProduct,
  isLinkProduct,
} from 'frontend/utils/helpers'
import React, { useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import styles from './Card.module.css'
import {
  Dot,
  ProgramCardMetadata,
  SeriesCardMetadata,
  SingleCardMetadata,
} from './CardMetaData'
import { isSingleProduct } from './CardUtils'
import CardLabel from './components/CardLabel/CardLabel'

type CardProps = {
  currentUrAssetId?: number
  product: Product
  layout?: 'vertical' | 'horizontal'
  withAnchor?: boolean
  className?: string
  isGridCard?: boolean
  cardLayout: EdithCardLayout
  children: React.ReactNode
  attributes?: React.Attributes
  isEditing?: boolean
  onClick?: () => void
}

const Card = ({
  product,
  className,
  isGridCard,
  cardLayout,
  children,
  withAnchor = true,
  isEditing = false,
  currentUrAssetId,
  ...attributes
}: CardProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const addHoverEffect = () => {
      if (styles.hoverEffect) {
        wrapperRef.current?.classList.add(styles.hoverEffect)
      }
    }

    const removeHoverEffect = () => {
      if (styles.hoverEffect) {
        wrapperRef.current?.classList.remove(styles.hoverEffect)
      }
    }

    if (wrapperRef.current && !isEditing) {
      wrapperRef.current.addEventListener('mouseover', addHoverEffect)
      wrapperRef.current.addEventListener('mouseout', removeHoverEffect)
    }

    return () => {
      if (wrapperRef.current && !isEditing) {
        wrapperRef.current.removeEventListener('mouseover', addHoverEffect)
        wrapperRef.current.removeEventListener('mouseout', removeHoverEffect)
      }
    }
  }, [isEditing])
  return (
    <article
      {...attributes}
      className={classnames(styles.card, className, {
        [`${styles.gridCard}`]: isGridCard,
        [`${styles.portraitCard}`]: cardLayout === EdithCardLayout.PORTRAIT,
        [`${styles.podCard}`]: cardLayout === EdithCardLayout.SQUARE,
        [`${styles.removeableCard}`]: !withAnchor,
        [`${styles.activeCard}`]: currentUrAssetId === (product as Program).id,
      })}
      data-exp="product-card"
      data-testid={`product-card-${(product as Program).id}`}
      data-exp-id={(product as Program).id}
    >
      <div className={styles.cardContainer} ref={wrapperRef}>
        {children}
      </div>
    </article>
  )
}

const ImageWithLayout = ({
  cardLayout,
  product,
  blockIndex,
  inView,
}: {
  cardLayout: EdithCardLayout
  product: Product | ContinuePlayingProgram
  inView: boolean
  blockIndex: number
}) => {
  const productId = 'id' in product && product.id

  switch (cardLayout) {
    case EdithCardLayout.PORTRAIT:
      return (
        <NextImage
          className={styles.cardLayoutImage}
          width={720}
          height={1080}
          src={`https://assets.ur.se/id/${productId}/images/1_po_1080.jpg`}
          {...(blockIndex < 3 && { priority: true })}
          fallbackSrc="/images/fallback_portrait.webp"
        />
      )
    case EdithCardLayout.SQUARE:
      return (
        <NextImage
          className={styles.cardLayoutImage}
          width={360}
          height={360}
          src={`https://assets.ur.se/id/${productId}/images/1_sql.jpg`}
          {...(blockIndex < 3 && { priority: true })}
        />
      )
    default:
      return 'imageUrl' in product ? (
        <NextImage
          width={480}
          className={styles.cardLayoutImage}
          height={270}
          src={product.imageUrl}
          {...(blockIndex < 3 && { priority: true })}
        />
      ) : (
        <NextImage
          oldResponsiveImage={{
            forceLowRes: !inView,
            sourceImage: product.image,
            type: ResponsiveImageType.CARD,
          }}
          className={styles.cardLayoutImage}
          width={640}
          height={360}
          src={product.image['640x360']}
          {...(blockIndex < 3 && { priority: true })}
        />
      )
  }
}

const Image = ({
  product,
  children,
  currentUrAssetId,
  cardLayout,
  blockIndex,
  isOnChildPage = false,
}: {
  product: Product | ContinuePlayingProgram
  children?: React.ReactNode
  currentUrAssetId?: number
  cardLayout: EdithCardLayout
  blockIndex?: number
  isOnChildPage?: boolean
}) => {
  const [detectIfInViewRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '300px',
    threshold: 0,
  })

  const accessibleUntil = handleAccessibleUntil(product)

  const isCurrentProduct =
    currentUrAssetId && currentUrAssetId === (product as Program).id

  return (
    <figure
      data-exp="card-figure"
      className={styles.figure}
      ref={detectIfInViewRef}
    >
      {children}
      <div className={styles.badgeImageContainer}>
        <CardLabel
          accessibleUntil={accessibleUntil}
          isCurrentProduct={Boolean(isCurrentProduct)}
          nextEpisodeLabel={
            'isNextEpisode' in product && product.isNextEpisode
              ? 'Spela nästa'
              : ''
          }
        />
        <div
          className={classnames(styles.imageWrap, {
            [`${styles.roundedCorners}`]: isOnChildPage,
          })}
        >
          <ImageWithLayout
            blockIndex={blockIndex ? blockIndex : 0}
            cardLayout={cardLayout}
            inView={inView}
            product={product}
          />
          {(product as Program).productType === 'program' && (
            <ProgressBar id={(product as Program).id} />
          )}
        </div>
      </div>
    </figure>
  )
}

const Heading = ({
  headingLevel,
  title,
  ...rest
}: {
  headingLevel: 2 | 3 | 4
  title: string
  className: string
  'data-exp': string
  'data-testid': string
}) => {
  switch (headingLevel) {
    case 2:
      return <h2 {...rest}>{title}</h2>
    case 3:
      return <h3 {...rest}>{title}</h3>
    case 4:
      return <h4 {...rest}>{title}</h4>
    default:
      return null
  }
}

const Metadata = ({
  product,
  isProgramOrSeriesPage = false,
  productType,
  currentUrAssetId,
  cardLayout,
  headingLevel,
  withAnchor = true,
  isRemoveCardDraft,
  onClick,
  hideCardMetaData = false,
}: {
  product: Product | ContinuePlayingProgram
  isProgramOrSeriesPage?: boolean
  productType: ProductType | null
  currentUrAssetId?: number
  cardLayout: EdithCardLayout
  headingLevel: 2 | 3 | 4
  withAnchor?: boolean
  isRemoveCardDraft?: boolean
  onClick?: () => void
  hideCardMetaData?: boolean
}) => {
  const isCurrentProduct =
    currentUrAssetId && currentUrAssetId === (product as Program).id
  const Anchor = ({
    children: anchorChildren,
    describedById,
  }: {
    children: React.ReactNode
    describedById: string
  }) => {
    return withAnchor ? (
      <SpaLink
        href={product.link}
        className={styles.cardLink}
        {...(onClick && {
          onClick,
        })}
        aria-describedby={describedById}
      >
        {anchorChildren}
      </SpaLink>
    ) : (
      <div className={styles.cardLink}>{anchorChildren}</div>
    )
  }

  const generateCardMetadata = () => {
    if (isLinkProduct(product)) {
      return null
    }

    if (productType === ProductType.SERIES)
      return <SeriesCardMetadata hideCardMetaData={hideCardMetaData} />

    if (productType && isSingleProduct(productType, product)) {
      return (
        <SingleCardMetadata
          hideCardMetaData={hideCardMetaData}
          duration={(product as Program).duration}
        />
      )
    }

    return (
      <ProgramCardMetadata
        duration={(product as Program).duration}
        episodeNumber={(product as Program).episodeNumber}
        isProgramOrSeriesPage={isProgramOrSeriesPage}
        seriesTitle={(product as Program).mainTitle}
        trailingDot={Boolean((product as Program).usp) && !hideCardMetaData}
      />
    )
  }

  const generateImageLabel = () => {
    const { isAudioDescribed, isSignLanguageInterpreted, format } =
      product as Program
    const isRadioProduct = format === MediaFormat.AUDIO
    const isExternalLink = isExternalLinkProduct(product as LinkProduct)
    const hasBadge =
      isAudioDescribed ||
      isExternalLink ||
      isRadioProduct ||
      isSignLanguageInterpreted

    return (
      <>
        <Badges
          isAudioDescribed={isAudioDescribed}
          isExternalLinkProduct={isExternalLink}
          isRadioProduct={isRadioProduct}
          isSignLanguageInterpreted={isSignLanguageInterpreted}
        />
        {hasBadge && <Dot />}
      </>
    )
  }

  return (
    <div
      data-testid={`card-metadata-${(product as Program).id}`}
      className={classnames(styles.metadata, {
        [`${styles.activeCardMetaData}`]: isCurrentProduct,
      })}
    >
      {!isRemoveCardDraft && (
        <>
          <Anchor describedById={product.describedById}>
            <Heading
              headingLevel={headingLevel}
              title={product.title}
              className={classnames(styles.title, {
                [`${styles.titleHover}`]: withAnchor,
              })}
              data-exp="card-title"
              data-testid="card-title"
            />
          </Anchor>
          {cardLayout === EdithCardLayout.DEFAULT && (
            <p
              data-exp="card-description"
              className={styles.description}
              id={product.describedById}
            >
              {generateImageLabel()}
              {generateCardMetadata()}
              {!hideCardMetaData && (
                <span data-exp="card-usp" className={styles.usp}>
                  {(isLinkProduct(product) && product.description) ||
                    (!isLinkProduct(product) &&
                      'usp' in product &&
                      product.usp)}
                </span>
              )}
            </p>
          )}
        </>
      )}
    </div>
  )
}

Card.Image = Image
Card.Metadata = Metadata

export default Card
