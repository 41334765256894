import React from 'react'
import styles from './ProgressBar.module.css'

type ProgressBarProps = {
  percentage?: number
}

const ProgressBar = ({ percentage = 0 }: ProgressBarProps) => (
  <div
    className={styles.progressBar}
    role="progressbar"
    aria-label="Procent spelat"
    aria-valuenow={percentage}
    aria-valuemin={0}
    aria-valuemax={100}
  >
    <div
      className={styles.progressIndicator}
      data-testid="progress-bar"
      style={{ width: `${percentage > 0 && percentage < 6 ? 6 : percentage}%` }}
    />
    <div className={styles.opacityBackground} />
  </div>
)

export default ProgressBar
