import React, { useEffect, useState } from 'react'
import { ProgressTracker } from 'components/UrPlayer/plugins/progressTracker/progressTracker'
import ProgressBar from './ProgressBar'
import styles from './ProgressBarContainer.module.css'

type ProgressBarContainerProps = {
  id: number
}

const ProgressBarContainer = ({ id }: ProgressBarContainerProps) => {
  const [programProgressPercentage, setProgramProgressPercentage] = useState(0)

  useEffect(() => {
    const savedPercentage = ProgressTracker.percentageById(id)

    if (savedPercentage) {
      setProgramProgressPercentage(savedPercentage)
    } else {
      setProgramProgressPercentage(0)
    }
  }, [id])

  return programProgressPercentage ? (
    <div className={styles.container}>
      <ProgressBar percentage={programProgressPercentage} />
    </div>
  ) : null
}

export default ProgressBarContainer
