import { DataLayerPageType } from 'frontend/enums'
import { useEffect } from 'react'
import { getCookie } from '../helpers'

export const pushPageViewToDataLayer = (
  pageType: DataLayerPageType,
  data: Record<string, string | string[] | number | undefined | false> = {},
) => {
  window?.dataLayer?.push({
    event: 'Pageview',
    pageType,
    gaCookie: getCookie('_ga'),
    peachCookie: getCookie('_pc_c'),
    ...data,
  })
}

const usePageTracking = (
  pageType: DataLayerPageType,
  data: Record<string, string | string[] | number> = {},
) => {
  useEffect(() => {
    const handleCookiebotAccept = () => {
      if (
        window.Cookiebot &&
        window.Cookiebot.consent.statistics &&
        window?.dataLayer
      ) {
        pushPageViewToDataLayer(pageType, data)
      }
    }

    if (window.Cookiebot && window.Cookiebot.consent.statistics) {
      pushPageViewToDataLayer(pageType, data)
    } else {
      window.addEventListener('CookiebotOnAccept', handleCookiebotAccept, false)
    }

    return () => {
      window.removeEventListener(
        'CookiebotOnAccept',
        handleCookiebotAccept,
        false,
      )
    }
  }, [pageType, data])
}

export default usePageTracking
