import { isBefore, parseISO } from 'date-fns'
import { SearchResponseType } from 'frontend/enums'
import { getSearchResults } from 'frontend/lib/Api'
import { Program } from 'frontend/types'
import {
  byIdInCustomOrder,
  handleAccessibleUntil,
  logError,
} from 'frontend/utils/helpers'
import {
  ProgramProgressData,
  ProgressData,
} from '../UrPlayer/plugins/progressTracker/progressTracker'
import { Age } from 'backend/services/v1/urApiService.enums'

export const FINISHED_WATCHING_THRESHOLD = 95

const byDateDescending = (left: { date: string }, right: { date: string }) => {
  return new Date(right.date).getTime() - new Date(left.date).getTime()
}

type IdAndProgress = [string | number, ProgramProgressData]

type IdAndDate = {
  id: string | number
  date: string
}

const isInProgress = ([, progress]: IdAndProgress) =>
  progress.percentage <= FINISHED_WATCHING_THRESHOLD

const getIdAndDate = ([id, progress]: IdAndProgress): IdAndDate => ({
  id,
  date: progress.date,
})

const getId = ({ id }: { id: string | number }) => String(id)

export const getInProgressPrograms = (
  programs?: Record<string, ProgramProgressData>,
): IdAndDate[] =>
  Object.entries(programs || {})
    .filter(isInProgress)
    .map(getIdAndDate)

export const getContinuePlayingProgramIds = (
  programs?: Record<string, ProgramProgressData>,
): string[] => {
  const inProgressPrograms = getInProgressPrograms(programs)

  return inProgressPrograms.sort(byDateDescending).map(getId)
}

export const selectAccessible = (results: Program[]) => {
  return results.filter(result => {
    const accessibleUntil = handleAccessibleUntil(result)

    if (!accessibleUntil) return false

    return isBefore(new Date(), parseISO(accessibleUntil))
  })
}

export const fetchData = async (
  progressData: ProgressData | null,
  onlyChildPrograms = false,
): Promise<Program[]> => {
  try {
    const programIds = getContinuePlayingProgramIds(progressData?.programs)

    if (!programIds.length) return []

    const programsData = await getSearchResults({
      id: programIds,
      response_type: SearchResponseType.LIMITED,
      ...(onlyChildPrograms && {
        typical_age_range: [
          Age.PRESCHOOL,
          Age.PRIMARY03,
          Age.PRIMARY46,
          Age.SPECIALSCHOOLPRIMARY03,
          Age.SPECIALSCHOOLPRIMARY46,
        ],
      }),
    })

    const { results } = programsData

    if (!results.length) return []

    results.sort(byIdInCustomOrder(programIds))

    return selectAccessible(results).slice(0, 24)
  } catch (error) {
    if (error) logError(error as Error)
    return []
  }
}
