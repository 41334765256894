import Head from 'next/head'
import React from 'react'

interface Props {
  title: string
  description: string
  sharePath: string
  type?: string
  image?: string
}

const OgMetadata = ({
  title = 'UR Play',
  description = '',
  sharePath = '/',
  type,
  image,
}: Props) => {
  const ogImage = image || 'https://urplay.se/og-logo.png'
  return (
    <Head>
      <meta property="og:title" content={title} />
      <meta property="og:locale" content="sv_SE" />
      <meta property="og:site_name" content="urplay.se" />
      <meta property="og:image" content={ogImage} />
      <meta property="og:url" content={`https://urplay.se${sharePath}`} />
      {type && <meta property="og:type" content={type} />}
      <meta property="og:description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@urofficiell" />
    </Head>
  )
}

export default OgMetadata
