import { FINISHED_WATCHING_THRESHOLD } from 'components/ContinuePlaying/helpers'
import { ProgramV2 } from 'frontend/types'
import { Plugin, UrPlayerConfig } from '../../types'
import { PlayerState } from 'components/UrPlayer/enums'

const PROGRESS_UPDATE_INTERVAL = 5000
export const LOCAL_STORAGE_KEY = 'ProgressTracker'

export interface ProgramProgressData {
  position: number
  percentage: number
  duration: number
  date: string
}

export interface ProgressData {
  programs: Record<string, ProgramProgressData>
}

export class ProgressTracker {
  private intervalId: NodeJS.Timeout | null = null

  private progressData: ProgressData = { programs: {} }

  constructor(
    private readonly jwPlayer: jwplayer.JWPlayer,
    private readonly urPlayerConfig: UrPlayerConfig,
    private accessibleEpisodes: ProgramV2[],
  ) {}

  call = () => {
    this.progressData = ProgressTracker.getSavedData() ?? this.progressData

    this.jwPlayer.on('play', this.startTracking)
    this.jwPlayer.on('pause', () => {
      this.savePlayerState()
      this.stopTracking()
    })
    this.jwPlayer.on('remove', () => {
      if (this.jwPlayer.getState() === PlayerState.PLAYING) {
        this.savePlayerState()
      }
      this.stopTracking()
    })
    this.jwPlayer.on('error', () => {
      this.savePlayerState()
      this.stopTracking()
    })
  }

  static getSavedData = (): ProgressData | null => {
    try {
      const savedData = localStorage.getItem(LOCAL_STORAGE_KEY)
      if (savedData) {
        return JSON.parse(savedData) as ProgressData
      }
      return null
    } catch (e) {
      return null
    }
  }

  static percentageById = (id: number): number | undefined => {
    const percentage =
      ProgressTracker.getSavedData()?.programs?.[id]?.percentage

    return percentage ? percentage : 0
  }

  static saveData = (progressData: ProgressData) => {
    try {
      return localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify(progressData),
      )
    } catch (e) {
      return null
    }
  }

  static trimProgramIds = (idsToKeep: number[]) => {
    const savedData = ProgressTracker.getSavedData()
    if (!savedData) return

    const trimmedPrograms = ProgressTracker.trimIdsFromData(
      idsToKeep,
      savedData,
    )

    ProgressTracker.saveData({
      programs: trimmedPrograms,
    })
  }

  private static readonly trimIdsFromData = (
    idsToKeep: number[],
    savedData: ProgressData,
  ) => {
    return Object.entries(savedData.programs).reduce(
      (acc, [id, programData]) =>
        idsToKeep.includes(Number(id))
          ? {
              ...acc,
              [id]: programData,
            }
          : acc,
      {},
    )
  }

  startTracking = () => {
    this.stopTracking()

    this.intervalId = setInterval(() => {
      this.savePlayerState()
    }, PROGRESS_UPDATE_INTERVAL)
  }

  stopTracking = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }

  savePlayerState = () => {
    const episodeIndex = this.accessibleEpisodes.findIndex(
      episode => this.urPlayerConfig.urAssetId === episode.id,
    )

    try {
      const currentPlayingData = this.makeProgramData()

      const nextEpisodeId = this.accessibleEpisodes[episodeIndex + 1]?.id

      const shouldAddNextEpisode =
        currentPlayingData.percentage > FINISHED_WATCHING_THRESHOLD &&
        episodeIndex > -1 &&
        nextEpisodeId &&
        !Object.keys(this.progressData.programs).includes(`${nextEpisodeId}`)

      this.progressData.programs = {
        ...this.progressData.programs,
        [this.urPlayerConfig.urAssetId]: currentPlayingData,
        ...(shouldAddNextEpisode && {
          [nextEpisodeId]: {
            position: 0,
            percentage: 0,
            duration: 0,
            date: new Date(),
          },
        }),
      }
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.progressData))
    } catch {
      this.stopTracking()
    }
  }

  makeProgramData = () => {
    return {
      position: this.jwPlayer.getPosition(),
      percentage: Math.round(
        (this.jwPlayer.getPosition() / this.jwPlayer.getDuration()) * 100,
      ),
      duration: this.jwPlayer.getDuration(),
      date: new Date(),
    }
  }
}

const progressTracker = (accessibleEpisodes: ProgramV2[]): Plugin => {
  return async (
    jwPlayer: jwplayer.JWPlayer,
    urPlayerConfig?: UrPlayerConfig,
  ) => {
    if (urPlayerConfig) {
      new ProgressTracker(jwPlayer, urPlayerConfig, accessibleEpisodes).call()
    }
  }
}

export default progressTracker
